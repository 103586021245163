.c-overlay {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 20;
    transform: translate(-50%, -50%);
    width: 822px;
    max-height: 90vh;
    padding: $spacing-1 $spacing-2 $spacing-2 $spacing-2;
    background: linear-gradient(45deg, $color-nightblue, $color-wine-dark);
    overflow-y: scroll;
    opacity: 0;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
  
    /* Hide scrollbar for IE, Edge and Firefox */

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &__title {
        display: flex;
        align-items: baseline;
    }

    &__content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 50px;
        grid-row-gap: $spacing-2;
    }
    &__text {
        color: $color-white;
        font-weight: $font-weight-light;
        font-size: $font-size * 0.9375;

        &:first-child {
            grid-column: 1 / 3;
        }

        &:nth-child(2) {
            grid-column: 1 / 2;
        }

        &:nth-child(3) {
            grid-column: 2 / 3;
        }
    }

    &__image {
        margin-bottom: $spacing-2;
    }

    &__close {
        color: $color-white;
        font-size: $font-size * 2;
        line-height: 1;
        text-align: right;

        .js-close {
            cursor: pointer;
        }
    }

    .e-heading--h2 {
        flex: 1;
        margin-top: 0;
        color: $color-white;
        font-weight: $font-weight-regular;
        font-size: $font-size * 1.5;
        text-align: center;
        letter-spacing: 0.15em;
        
    }

    .e-heading--h4 {
        margin: 0;
        font-weight: $font-weight-medium;
        font-size: $font-size * 0.9375;
        text-align: center;
    }

    .e-paragraph {
        margin-top: 0;
        &:last-child {
            margin-bottom: 0;
        }
    }

    &.is-open {
        display: block;
        opacity: 1;
    }
}