$color-black:        #000000 !default;
$color-black-off1:   rgba($color-black, 0.9) !default;
$color-black-off2:   rgba($color-black, 0.7) !default;
$color-black-off3:   rgba($color-black, 0.5) !default;

$color-white:        #ffffff !default;
$color-white-off1:   rgba($color-white, 0.9) !default;
$color-white-off2:   rgba($color-white, 0.7) !default;
$color-white-off3:   rgba($color-white, 0.5) !default;

$color-wine:        #ca2576;
$color-wine-dark:   #6d0d2f;
$color-aqua:        #39c3dc;
$color-lime:        #adfb00;
$color-nightblue:   #2f3b61;


$color-text:        $color-black !default;
$color-link:        $color-wine !default;
$color-link--hover: $color-wine !default;
