.c-trends {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
    grid-column: trends-start / trends-end;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
  
    /* Hide scrollbar for IE, Edge and Firefox */

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &__item {
        margin-bottom: $spacing-1;
        padding: $spacing-0-5;
        color: $color-white;
        font-size: $font-size;
        font-weight: $font-weight-regular;
        text-align: right;
        background: linear-gradient(-90deg, transparent, rgba($color-aqua, 0.8) var(--start), rgba($color-aqua, 0.8) var(--end), transparent);
        background-repeat: no-repeat;
        background-position: 1000px;
        animation-name: background-left;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-delay: 0.25s;
        cursor: pointer;
    }
}


.c-fields {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
    grid-column: fields-start / fields-end;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
  
    /* Hide scrollbar for IE, Edge and Firefox */

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &__item {
        margin-bottom: $spacing-1;
        padding: $spacing-0-5;
        color: $color-white;
        font-size: $font-size;
        font-weight: $font-weight-regular;
        background: linear-gradient(90deg, transparent, rgba($color-lime, 0.8) var(--start), rgba($color-lime, 0.8) var(--end), transparent);
        background-repeat: no-repeat;
        background-position: -1000px;
        animation-name: background-right;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-delay: 0.25s;
        cursor: pointer;
    }
}

@keyframes background-left {
    0% {
        background-position: 1000px;
    }

    100% {
        background-position: 0px;
    }
}

@keyframes background-right {
    0% {
        background-position: -1000px;
    }

    100% {
        background-position: 0px;
    }
}

