@import '../../settings/settings.colors';

.c-figure {
    margin: 0;

    img {
        display: block;
        height: 100%;
    }

    &__caption {
        margin-top: 0.7rem;
        

        a {
            @extend .e-link;

            &::before {
                
            }

            &:link,
            &:visited {
                
            }

            &:hover,
            &:focus,
            &:active {
                
                &::before {
                    
                }
            }
        }

        strong, b {
            font-weight: 500;
        }
    }
}
