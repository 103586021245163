body {
    font-family: $font-family--body;
    background-image: url('../media/images/hintergrund_radar.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.l-page {
    max-width: $global-section-max-width;
    margin: 0 auto;
}