@import '../settings/settings.colors';
@import '../settings/settings.typography';
@import '../tools/tools.mq';

html {
    color: $color-text;
    font-size: $font-size;
    font-family: $font-family--body;
    line-height: $line-height;
    -ms-touch-action: none !important;

    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;

    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern=1";
    font-feature-settings: "kern";
    -moz-osx-font-smoothing: grayscale;

    scroll-behavior: smooth;

    @include mq($until: tablet) {
        font-size: $font-size--mobile;
        line-height: $line-height--mobile;
    }
}

body {
    hyphens: auto;
    background-color: $color-white;
    -webkit-overflow-scrolling: touch;
}
