@import '../tools/tools.mq';

[style*="--aspect-ratio"] > :first-child {
    width: 100%;
}

[style*="--aspect-ratio"] > img {
    height: auto;
}

@supports (--custom:property) {
    [style*="--aspect-ratio"] {
        position: relative;
    }

    [style*="--aspect-ratio"]::before {
        float: left;
        width: 1px;
        height: 0;
        margin-left: -1px;
        padding-bottom: calc(100% / (var(--aspect-ratio)));
        content: "";

        @include mq($from: desktop) {
            padding-bottom: calc(100% / (var(--aspect-ratio-desktop, var(--aspect-ratio))));
        }
    }

    [style*="--aspect-ratio"]::after {
        display: table;
        clear: both;
        content: "";
    }

    [style*="--aspect-ratio"] > :first-child {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }

    [style*="--aspect-ratio"] img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        /* stylelint-disable */
        font-family: 'object-fit: cover;';
        /* stylelint-enable */
    }
}
