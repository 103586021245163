@import '../settings/settings.colors';

.e-link {
    font-size: inherit;
    text-decoration: none;

    &:link,
    &:visited {
        color: $color-link;
    }

    &:hover,
    &:focus,
    &:active {
        color: $color-link--hover;
    }
}
