@import '../settings/settings.spacings';
@import '../settings/settings.typography';

$h1-font-size: 3rem;
$h2-font-size: 1.75rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.25rem;
$h5-font-size: 1rem;
$h6-font-size: 0.8rem;


$h1-font-size--mobile: 2rem;
$h2-font-size--mobile: 1.6rem;
$h3-font-size--mobile: 1.4rem;
$h4-font-size--mobile: 1.2rem;
$h5-font-size--mobile: 1rem;
$h6-font-size--mobile: 0.8rem;


.e-heading {
    font-family: $font-family--body;

    &--h1 {
        margin-top: $spacing-0-5;
        margin-bottom: $spacing-1;
        font-size: $h1-font-size;
    }

    &--h2 {
        margin-top: $spacing-0-5;
        margin-bottom: $spacing-1;
        font-size: $h2-font-size;
    }

    &--h3 {
        margin-top: $spacing-0-5;
        margin-bottom: $spacing-1;
        font-size: $h3-font-size;
    }

    &--h4 {
        margin-top: $spacing-0-5;
        margin-bottom: $spacing-1;
        font-size: $h4-font-size;
    }

    &--h5 {
        margin-top: $spacing-0-5;
        margin-bottom: $spacing-1;
        font-size: $h5-font-size;
    }

    &--h6 {
        margin-top: $spacing-0-5;
        margin-bottom: $spacing-1;
        font-size: $h6-font-size;
    }

    &--jumbo {
        margin-top: 0;
        margin-bottom: 0;
        font-weight: $font-weight-light;
        font-size: $font-size * 4.25;
        text-transform: uppercase;
        letter-spacing: 0.3em;
    }
}
