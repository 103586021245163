.c-header {
    position: sticky;
    top: 0;
    z-index: 10;
    margin-bottom: $spacing-3;
    
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: $global-section-max-width;
        margin: 0 auto;
        padding: 20px $global-section-side-padding 0  $global-section-side-padding;
    }

    &__title {
        margin: 0 auto;
        color: $color-white;
        font-weight: $font-weight-light;
        font-size: $font-size * 1.75;
        text-transform: uppercase;
        letter-spacing: 0.3em;
    }

    &__logo {
        flex: 0 0 20%;
        svg path {
            fill: $color-white;
        }
    }

    &__nav-trigger {
        flex: 0 0 20%;
        text-align: right;
    }
}
