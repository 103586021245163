.l-radar {
    &__inner {
        
    }

    &__topics {
        display: grid;
        grid-template-columns: [trends-start] repeat(5, [l-col] 1fr) [trends-end] minmax(3.5%, 1fr) [fields-start] repeat(5, [r-col] 1fr) [fields-end];
        margin-bottom: $spacing-2;
    }

    &__timeline {
        display: grid;
        grid-template-columns: [trends-start] repeat(5, [l-col] 1fr) [trends-end] minmax(3.5%, 1fr) [fields-start] repeat(5, [r-col] 1fr) [fields-end];
        margin-bottom: $spacing-1-5;
    }

    &__items {
        position: fixed;
        top: 236px;
        right: 0;
        bottom: 0;
        left: 0;
        display: grid;
        grid-template-columns: [trends-start] repeat(5, [l-col] 1fr) [trends-end] minmax(3.5%, 1fr) [fields-start] repeat(5, [r-col] 1fr) [fields-end];
    }

    &__megatrend-title {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        grid-column: trends-start / trends-end;
        color: $color-aqua;
        font-size: $font-size * 1.125;
        text-align: right;

        .icon {
            margin-right: $spacing-1;
            path {
                fill: $color-aqua;
            }
            
        }
    }

    &__fields-title {
        display: flex;
        align-items: center;
        grid-column: fields-start / fields-end;
        color: $color-lime;
        font-size: $font-size * 1.125;

        .icon {
            margin-left: $spacing-1;
            path {
                fill: $color-lime;
            }
        }
    }

    &__year {
        color: $color-white;
        font-size: $font-size * 0.9375;
        font-weight: $font-weight-light;
        text-align: center;


        &--megatrend-2021 {
            grid-column: 5 l-col / trends-end;
            grid-row: 1 / 2;
        }

        &--megatrend-2022 {
            grid-column: 4 l-col / 5 l-col;
            grid-row: 1 / 2;
        }
        
        &--megatrend-2023 {
            grid-column: 3 l-col / 4 l-col;
            grid-row: 1 / 2;
        }
        
        &--megatrend-2024 {
            grid-column: 2 l-col / 3 l-col;
            grid-row: 1 / 2;
        }

        &--now {
            grid-column: trends-end / fields-start;
            grid-row: 1 / 2;
        }

        &--field-2021 {
            grid-column: 1 r-col / 2 r-col;
        }
        
        &--field-2022 {
            grid-column: 2 r-col / 3 r-col;
            grid-row: 1 / 2;
        }
        
        &--field-2023 {
            grid-column: 3 r-col / 4 r-col;
            grid-row: 1 / 2;
        }
        
        &--field-2024 {
            grid-column: 4 r-col / 5 r-col;
            grid-row: 1 / 2;
        }
    }

    &__toolbox {
        position: fixed;
        bottom: 120px;
        left: 50%;
        width: 152px;
        height: auto;
        transform: translateX(-50%);

        img {
            max-width: 100%;
            height: auto;
        }
    }
}