.c-login {

    &__inner {
        position: relative;
    }

    &__form {
        display: flex;

    }

    &__field {
        margin-left: $spacing-1;
        border: 2px solid rgba($color-white, 0.3);
        border-radius: 3px;

        &--submit {
            border: 2px solid $color-white;
        }

        &:first-child {
            margin-left: 0;
        }
    }

    &__input {
        padding: 0 $spacing-0-5;
        color: $color-white;
        font-weight: $font-weight-light;
        font-size: $font-size * 0.875;
        letter-spacing: 0.07em;
        line-height: $spacing-2;
        text-align: center;
        border: none;
        background-color: transparent;
        outline: 0;

        &--submit {
            padding: 0 $spacing-1;
            color: $color-black;
            background-color: $color-white;
        }

        &::placeholder {
            color: $color-white;
        }

        &:focus {
            &::placeholder {
                color: transparent;
            }
        }
    }

    &__error {
        position: absolute;
        left: 0;
        height: 0;
        color: $color-white;
        opacity: 0;
        transition: opacity $global-transition-duration $global-transition-timing, height $global-transition-duration $global-transition-timing;

        &.is-visible {
            height: auto;
            opacity: 1;
        }
    }
}