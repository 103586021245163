// set the font-faces in elements/elements.font-face.scss

$font-family--body: 'Roboto', serif;
$font-family--accent: 'Accent', sans-serif;
$font-family--icons: 'Icons', sans-serif;

/**
 * Responsive font-size
 *
 * min(18px, max(16px, calc(10px + 0.7vw)))
 *      |         |                 |
 *   max fz     min fz         max content width
 *
 *      max fz - 10
 * vw = ------------ * 100
 *      max-w
 */
$font-size:    1rem;
$line-height:  1.5;

$font-size--mobile:    14px;
$line-height--mobile:  1.3;

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;

