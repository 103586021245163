// Basic Dimensions
$global-content-max-width:         1440px;
$global-section-side-padding:      1.5rem;
$global-section-max-width:         calc(#{$global-content-max-width} + 2 * #{$global-section-side-padding});

$global-grid-template-columns: repeat(12, 1fr);
$global-grid-column-gap: 28px;

// Breakpoints for sass-mq module
$mq-breakpoints: (
    mobile:  320px,
    phablet: 540px,
    tablet:  840px,
    desktop: 1120px,
    wide:    1440px
);


// Transitions, Animations
$global-transition-duration:       250ms !default;
$global-transition-timing:         ease-in-out !default;
$global-transition-timing:         cubic-bezier(.25,0,.25,1) !default;

$global-joinimation-translate:     1.5rem !default;
$global-joinimation-transformTime: 0.2s !default;
$global-joinimation-opacityTime:   0.4s !default;

$global-link-arrow-shift:          0.2em !default;
