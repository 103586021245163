.c-front-title {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    color: $color-white;
    text-align: center;

    .e-heading {
        width: 100%;
    }

    .e-heading--h3 {
        font-weight: $font-weight-thin;
        font-size: $font-size * 1.3125;
        text-transform: uppercase;
        letter-spacing: 0.07em;
        margin: 0;
    }
}