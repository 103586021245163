$spacing-baseline:  1rem !default;

$spacing-0-5:       $spacing-baseline * 0.5 !default;
$spacing-0-75:      $spacing-baseline * 0.75 !default;
$spacing-1:         $spacing-baseline * 1 !default;
$spacing-1-5:       $spacing-baseline * 1.5 !default;
$spacing-2:         $spacing-baseline * 2 !default;
$spacing-2-5:       $spacing-baseline * 2.5 !default;
$spacing-3:         $spacing-baseline * 3 !default;
$spacing-4:         $spacing-baseline * 4 !default;
$spacing-6:         $spacing-baseline * 6 !default;
$spacing-8:         $spacing-baseline * 8 !default;


$spacing-baseline--mobile:  15px !default;

$spacing-0-5--mobile:       $spacing-baseline--mobile * 0.5 !default;
$spacing-0-75--mobile:      $spacing-baseline--mobile * 0.75 !default;
$spacing-1--mobile:         $spacing-baseline--mobile * 1 !default;
$spacing-1-5--mobile:       $spacing-baseline--mobile * 1.5 !default;
$spacing-2--mobile:         $spacing-baseline--mobile * 2 !default;
$spacing-2-5--mobile:       $spacing-baseline--mobile * 2.5 !default;
$spacing-3--mobile:         $spacing-baseline--mobile * 3 !default;
$spacing-4--mobile:         $spacing-baseline--mobile * 4 !default;
$spacing-6--mobile:         $spacing-baseline--mobile * 6 !default;
$spacing-8--mobile:         $spacing-baseline--mobile * 8 !default;
